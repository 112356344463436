import { computed } from "@vue/reactivity";
import { ref, type ComputedRef } from "vue";

/**
 * An utility method for manually tracking and recomputing a given Vue3 computed reference.
 *
 * @template T
 * @param {ComputedRef<T>} computedRef - The computed reference to be tracked and recomputed.
 * @returns An object containing:
 *   - `computedRef`: A computed reference that updates whenever recompute is called.
 *   - `recompute`: A function to manually trigger recomputation.
 */
export function useManualTracking<T>(getter: () => T): { computedRef: ComputedRef<T>, recompute: () => void } {
  const counter = ref(0)
  const computedRef = computed(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    counter.value

    return getter()
  })

  return {
    recompute: () => void counter.value++,
    computedRef
  }
}